<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">工程收益</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-date-range label="日期" ref="dateRange" :pickerOptions="pickerOptions" :minDate.sync="pageParam.params.startTime" :maxDate.sync="pageParam.params.endTime" valueFormat="yyyy-MM-dd HH:mm:ss" :clearable='false' />
                <le-select-remote-search label="站点" v-model="pageParam.params.stationId" :options="stationFilterOptions" placeholder="请选择" />
                <le-select-remote-search label="项目" v-model="pageParam.params.incomeId" :options="incomeFilterOptions" placeholder="请选择" />
                <le-select-remote-search label="工程商户" v-model="pageParam.params.companyId" :options="companyFilterOptions" placeholder="请选择" />
                <le-select-local-search label="状态" v-model="pageParam.params.incomeStatus" :options="incomeStatusDic" />
            </le-search-form>
            <le-pagview ref="engineeringIncome" @setData="setTableData" :pageParam="pageParam" :pagerCount="5" :isFixed="true" :tableRef="$refs.engineeringIncomeList">
                <el-table 
                    ref="engineeringIncomeList" 
                    :data="tableData" 
                    :highlight-current-row="true" 
                    v-sticky="{ top: 0, parent:'.el-card__body' }" 
                    style="width: 100%">
                    <el-table-column prop="recordDate" label="分账日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.countDate || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paymentName" label="项目名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="incomePrice" label="站点名称" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDate" label="收款工程商户" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.companyName || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="分账金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.incomePrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="endDate" label="入账状态" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.incomeStatus | initDic(incomeStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="导出账单" placement="top">
                                <img src="../../assets/icon/option-export.png" class="a-wh-16" @click="handleExport(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.engineerIncomePage,
                    method: "post",
                    params: {
                        startTime: '',
                        endTime: '',
                        stationId: '',
                        incomeId: '',
                        companyId: '',
                        incomeStatus: ''
                    },
                    freshCtrl: 1,
                },
                choiceDate: '',
                pickerOptions: {
                    onPick: ({ maxDate, minDate }) => {
                        this.choiceDate = minDate.getTime()
                        if (maxDate) this.choiceDate = ''
                    },
                    disabledDate: (time) => {
                        if (this.choiceDate) {
                            const one = 6 * 24 * 3600 * 1000
                            const minTime = this.choiceDate - one
                            const maxTime = this.choiceDate + one
                            return time.getTime() < minTime || time.getTime() > maxTime
                        }else{
                            return ''
                        }
                    },
                },
                companyFilterOptions: {
                    url: this.$Config.apiUrl.getUnderCompany,
                    method: "post",
                    params: {
                        companyType: 3,
                        isSignAgency: 1,
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },
                incomeFilterOptions: {
                    url: this.$Config.apiUrl.incomeDistriList,
                    method: "post",
                    params: {
                        queryCompanyId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                    pageSize: 100
                },//项目数据
                stationFilterOptions: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {
                        incomeId: ''
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",  //incomeDistriId
                    pageSize: 100
                },//站点数据
                incomeStatusDic: []
            }
        },
        created () {
            this.$getDic('incomeStatus','select').then(res=>{ this.incomeStatusDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data.map(item=>{
                    return {
                        ...item,
                    }
                });
            },

            handlerRest() {
                this.pageParam.params = {
                    startTime: '',
                    endTime: '',
                    stationId: '',
                    incomeId: '',
                    companyId: '',
                    incomeStatus: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['engineeringIncome'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            handleExport (datas) {
                this.$exfile({
                    code: 23,
                    fileName: datas.countDate + '-' + datas.companyName + ' 工程收益明细账单',
                    startTime: datas.countDate,
                    endTime: datas.countDate,
                    params: {
                        companyIncomeId: datas.id
                    }
                })
            },
            exportfile () {
                let fileName = this.pageParam.params.startTime + ' 至 ' + this.pageParam.params.endTime + ' 工程收益统计账单'
                if(!this.pageParam.params.startTime || !this.pageParam.params.endTime) {
                    fileName = '工程收益统计账单'
                }
                this.$exfile({
                    code: 24,
                    fileName: fileName,
                    startTime: this.pageParam.params.startTime,
                    endTime: this.pageParam.params.endTime,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
    /deep/ .companySelect{
        .a-ml-20{
            margin-left: 10px !important;
        }
    }
    .panel-cell-error{
        color: #ffffff;
        background: #F56C6C !important;
        border-radius: 4px;
        padding: 2px 10px;
        margin: 2px 0;
        width: 100%;
    }
    .panel-cell-errorExpenses{
        color: #ffffff;
        background: #F56C6C !important;
        border-radius: 4px;
        padding: 2px 10px;
        margin: 2px 0;
        width: 100%;
    }
</style>